import Cookies from 'js-cookie';
import moment from 'moment'

export const Config = {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  SECRET_KEY: process.env.REACT_APP_SECRET_KEY,
  LOGO_TEXT: process.env.REACT_APP_LOGO_TEXT,
  LOGO_IMG: process.env.REACT_APP_LOGO_IMG,
}
const encryptor = require('simple-encryptor')(Config.SECRET_KEY);


export function encrypt(val){
  return encryptor.encrypt(val);
}

export function decrypt(val){
  return encryptor.decrypt(val);
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const cardFormat = function(str,prefix='-'){
  if (!str) return;
  return str.toString().replace(/(\d{4}(?!\s))/g, "$1" + prefix).replace(/-$/,'');
};

export const intFormat = function(str,prefix='-'){
  if (!str) return;
  return str.toString().replace(/[^0-9]/g,'');
};

export const moneyFormat = function(str){
  if (!str) return;
  return str.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const getDateRange = function(value){
  let form = {}
  switch(value){
    case 'lastweek':
      form.startDate = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')
      form.endDate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
      break

    case 'thisweek':
      form.startDate = moment().startOf('week').format('YYYY-MM-DD')
      form.endDate = moment().endOf('week').format('YYYY-MM-DD')
      break

    case 'nextweek':
      form.startDate = moment().add(1,'week').startOf('week').format('YYYY-MM-DD')
      form.endDate = moment().add(1,'week').endOf('week').format('YYYY-MM-DD')
      break

    case 'lastmonth':
      form.startDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
      form.endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      break

    case 'thisyear':
    case 'lastyear':
    case 'thismonth':
      value = 'thismonth'
      form.startDate = moment().startOf('month').format('YYYY-MM-DD')
      form.endDate = moment().endOf('month').format('YYYY-MM-DD')
      break

    case 'nextmonth':
      form.startDate = moment().add(1,'month').startOf('month').format('YYYY-MM-DD')
      form.endDate = moment().add(1,'month').endOf('month').format('YYYY-MM-DD')
      break

    case 'next2month':
      form.startDate = moment().add(2,'month').startOf('month').format('YYYY-MM-DD')
      form.endDate = moment().add(2,'month').endOf('month').format('YYYY-MM-DD')
      break

    /*
    case 'lastyear':
      form.startDate = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD')
      form.endDate = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')
      break

    case 'thisyear':
      form.startDate = moment().startOf('year').format('YYYY-MM-DD')
      form.endDate = moment().endOf('year').format('YYYY-MM-DD')
      break
    */

    default: break

  }

  form.option = value
  return form
}


class AuthSession {
  cookieToken = '_authtoken'; // userdata
  cookieAuthAsset = '_authwebpmsasset'; // group access

  setToken(token){
    Cookies.set(this.cookieToken,encrypt(token), {expires: 30, path:'/'});
  }

  getToken(){
    return decrypt(Cookies.get(this.cookieToken))
  }

  isLoggedin(){
    return this.getToken() ? true : false
  }

  getAsset(){
    let asset = window.localStorage.getItem(this.cookieAuthAsset);
    if(asset){
      return JSON.parse(asset)
    }
  }
  setAsset({tid,title}){
    window.localStorage.setItem(this.cookieAuthAsset,JSON.stringify({
      'tid': tid,
      'title': title
    }))
  }

  clean(){
    Cookies.remove(this.cookieToken)
    localStorage.removeItem(this.cookieToken)
    localStorage.removeItem('maintenanceFilter')
    localStorage.removeItem('historyFilter')
    localStorage.removeItem('reportFilter')
    //localStorage.removeItem(this.cookieAuthAsset)
  }
}

export default new AuthSession();
