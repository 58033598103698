/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState,useEffect} from "react"
import { useSelector,useDispatch } from 'react-redux'
import {
  CustomInput,
  NavItem,
  NavLink
} from "reactstrap"

import * as Icon from "react-feather"
import API from "../../../helpers/api"
import ReactSelect from 'react-select';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";


function NavbarVessel(props){
  let {sidebarVisibility} = props;
  const asset = useSelector(state => state.auth.asset)
  const userAssets = useSelector(state => state.auth.userAssets)
  const refreshAssets = useSelector(state => state.auth.refreshAssets)
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false)
  const [vessel, setVessel] = useState(0);
  const [optionsData,setOptionsData] = useState([])
  const [error,setError] = useState('')

  useEffect(() => {

    if(!refreshAssets) return
    setLoading(true)

    API.get('/v1/users/assets',{
      'params':{
        page: 1,
        rows: -1,
        sort: JSON.stringify([{"field":'tid_assets',"order":'desc'}]),
      },
      callback: ({success,data})=>{
        setLoading(false)
        if(success){
          let list = data.list || []
          let newAssets = []

          list.forEach((k)=>{
            newAssets.push({
              tid: k.tid_assets,
              title: k.title
            })
          })
          dispatch({type:'CHANGE_USER_ASSETS',value: newAssets})
        }else{
          setError(data.general)
        }
      }
    })

  }, [refreshAssets]);

  useEffect(() => {
    let newData = []

    userAssets.forEach((k)=>{
      newData.push({
        label: k.title,
        value: k.tid.toString()
      })
    })

    setOptionsData(newData)

  }, [userAssets]);

  useEffect(() => {
    if(asset){
      setVessel(asset.tid)
    }
  }, [asset]);


  const onChange = (e) => {
    let {value,selectedIndex} = e.target;

    let label = e.target[selectedIndex].text
    //let {label,value} = obj
    setVessel(value)
    dispatch({type:'CHANGE_ASSET',value: {
      'tid': value,
      'title': label
    }})

  }
  const onChangeReactSelect = (obj) => {
    //let {value,selectedIndex} = e.target;

    //let text = e.target[selectedIndex].text
    let {label,value} = obj
    setVessel(value)
    dispatch({type:'CHANGE_ASSET',value: {
      'tid': value,
      'title': label
    }})

  }


  return (
    <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
      <ul className="navbar-nav d-xl-none">
        <NavItem className="mobile-menu mr-auto">
          <NavLink
            className="nav-menu-main menu-toggle hidden-xs is-active"
            onClick={sidebarVisibility}>
            <Icon.Menu className="ficon" />
          </NavLink>
        </NavItem>
      </ul>
      <div className="nav navbar-nav">

          <div style={{width:'190px'}}>

            {
              (error)
                ? <span className="text-danger">{error}</span>
                : (isMobile)
                  ? <CustomInput type="select" id="vesselGlobal" value={vessel} onChange={onChange}>
                    {
                      userAssets.map((k,key)=>{
                        return(
                          <option key={key} value={k.tid}>{k.title}</option>
                        )
                      })
                    }
                    </CustomInput>
                  : <ReactSelect
                      className="react-select-container"
                      isLoading={loading}
                      options={optionsData}
                      onChange={onChangeReactSelect}
                      value={optionsData.filter(function(option) {
                        return option.value.toString() === vessel.toString();
                      })}
                      placeholder="Choose Vessel..."
                    />

            }

          </div>

      </div>

    </div>
  )
}

export default NavbarVessel
