import { combineReducers } from "redux"
import customizer from "./customizer/"
import AuthSession from './../../helpers/global'

const rootReducer = combineReducers({
  customizer: customizer,
  auth: (
    state={
      permissionAction:[],
      userdata: '',
      userNavigation: '',
      asset: '',
      userAssets: [],
      refreshAssets: '',
      refreshNotify: ''
    },
    action
  )=>{

    switch(action.type){
      case 'CHANGE_PERMISSION_ACTION':
        return {...state,permissionAction: action.value}
      case 'CHANGE_USERDATA':
        return {...state,userdata: action.value}
      case 'CHANGE_ASSET':
        AuthSession.setAsset(action.value)
        return {...state,asset: action.value}
      case 'CHANGE_USER_ASSETS':
        return {...state,userAssets: action.value}
      case 'CHANGE_USER_NAVIGATION':
        return {...state,userNavigation: action.value}
      case 'CHANGE_REFRESH_ASSETS':
          return {...state,refreshAssets: new Date()}
      case 'CHANGE_REFRESH_NOTIFY':
        return {...state,refreshNotify: new Date()}
      default:
        return state;
    }

  }
})

export default rootReducer
