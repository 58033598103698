import React,{useEffect,useState} from "react"
//import { connect } from "react-redux"
import { useSelector } from 'react-redux'
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
//import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
//import classnames from "classnames"
//import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import { history } from "../../../history"
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent"

import API from '../../../helpers/api'

const UserDropdown = props => {

  const onGo = (path)=>{
    history.push(path)
  }

  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="#" className="dropdown-menu-header d-sm-none">
        <div className="dropdown-header mt-0">
          <span className="notification-title">Hi, {props.userdata.username}</span>
        </div>
      </DropdownItem>

      <DropdownItem divider className="d-sm-none" />

      <DropdownItem tag="a" onClick={()=>onGo('/profile')}>
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>

      <DropdownItem tag="a" onClick={()=>onGo('/profile/password')}>
        <Icon.Lock size={14} className="mr-50" />
        <span className="align-middle">Password</span>
      </DropdownItem>

      <DropdownItem divider className="d-none d-sm-block" />

      <DropdownItem
        tag="a"
        href="#"
        onClick={e => history.push("/logout")}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

function NavbarUser(props){
  const userdata = useSelector(state => state.auth.userdata)
  const refreshNotify = useSelector(state => state.auth.refreshNotify)
  const [refresh,setRefresh] = useState('')

  const [notify,setNotify] = useState({
    'MO_COMPLETE': '',
    'MO_CLOSE': '',
    'SO_AUTHORIZE': '',
    'SO_APPROVE': '',
    'SO_COMPLETE': '',
    'SO_CLOSE': ''
  })
  const [notifyTotal,setNotifyTotal] = useState(0)
  const [error,setError] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    let t = setInterval(() => {
      setRefresh(new Date())
    }, 60000 * 5); // 5 minutes

    return () => {
      clearTimeout(t)
    };

  }, []);


  useEffect(() => {

    let newNotify = {
      'MO_COMPLETE': '',
      'MO_CLOSE': '',
      'SO_AUTHORIZE': '',
      'SO_APPROVE': '',
      'SO_COMPLETE': '',
      'SO_CLOSE': ''
    }

    API.get('/v1/maintenance/jobs/mo_so_notif',{
      params: {
        page: 1,
        rows: 1,
        filter: JSON.stringify([{"field":"tid_users","op":"eq","value":1}]),
        sort: JSON.stringify([])
      },
      callback: ({success,data})=>{

        if(success){


          let total = 0
          let list = data.list || []

          list.forEach((k)=>{
            let key = k.type_maintenance.toUpperCase() + '_' + k.nextstep.toUpperCase()
            newNotify[key] = {
              "tid_users": k.tid_users,
              "type_maintenance": k.type_maintenance,
              "status": k.status,
              "jumlah": k.jumlah ? parseInt(k.jumlah) : 0,
              "nextstep": k.nextstep,
            }
            total = total + parseInt(k.jumlah)
          })

          setNotifyTotal(total)
          setNotify(newNotify)

        }else{
          setError(data.general)
        }
      }
    })

  }, [refreshNotify,refresh]);

  const goDetail = (status,type,be)=>{
    if(status){
      history.push( '/notify?status=' + status + '&type=' + type + '&be=' + be)
      setDropdownOpen(false)
    }
  }

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return(
    <ul className="nav navbar-nav navbar-nav-user float-right">

      <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle}
        tag="li"
        className="dropdown-notification nav-item">

        <DropdownToggle tag="a" className="nav-link nav-link-label">
          <Icon.Bell size={25} />
          <Badge pill color="primary" className="badge-up">
            {" "}{notifyTotal}{" "}
          </Badge>
        </DropdownToggle>

        <DropdownMenu tag="ul" right className="dropdown-menu-media">
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              <span className="notification-title">MO/SO Notifications</span>
            </div>
          </li>
          <div className="media-list">

            <div className="d-flex justify-content-between">
              <Media className="d-flex align-items-start" onClick={() => goDetail(notify['MO_COMPLETE'].status, notify['MO_COMPLETE'].type_maintenance,'Complete') }>
                <Media left>
                  <Icon.CheckCircle
                    className="font-medium-5 primary"
                    size={21}
                  />
                </Media>
                <Media body>
                  <Media heading className="media-heading" tag="h6">
                    MO to be Complete
                  </Media>
                  <p className="notification-text">
                    Complete the task
                  </p>
                </Media>
                <div className="media-meta">
                  <Badge pill color={notify['MO_COMPLETE'].jumlah > 0 ? "primary" : "light" }>{notify['MO_COMPLETE'].jumlah || 0}</Badge>
                </div>
              </Media>
            </div>


            <div className="d-flex justify-content-between">
              <Media className="d-flex align-items-start" onClick={() => goDetail(notify['MO_CLOSE'].status, notify['MO_CLOSE'].type_maintenance,'Close')}>
                <Media left>
                  <Icon.XSquare
                    className="font-medium-5 danger"
                    size={21}
                  />
                </Media>
                <Media body>
                  <Media heading className="media-heading" tag="h6">
                    MO to be Close
                  </Media>
                  <p className="notification-text">
                    Close the task
                  </p>
                </Media>
                <div className="media-meta">
                  <Badge pill color={notify['MO_CLOSE'].jumlah > 0 ? "primary" : "light" }>{notify["MO_CLOSE"].jumlah || 0}</Badge>
                </div>
              </Media>
            </div>

            <div className="d-flex justify-content-between">
              <Media className="d-flex align-items-start" onClick={() => goDetail(notify['SO_AUTHORIZE'].status, notify['SO_AUTHORIZE'].type_maintenance,'Authorize')}>
                <Media left>
                  <Icon.Lock
                    className="font-medium-5 warning"
                    size={21}
                  />
                </Media>
                <Media body>
                  <Media heading className="media-heading" tag="h6">
                    SO to be Authorize
                  </Media>
                  <p className="notification-text">
                    Authorize the task
                  </p>
                </Media>
                <div className="media-meta">
                  <Badge pill color={notify['SO_AUTHORIZE'].jumlah > 0 ? "primary" : "light" }>{notify["SO_AUTHORIZE"].jumlah || 0}</Badge>
                </div>
              </Media>
            </div>

            <div className="d-flex justify-content-between">
              <Media className="d-flex align-items-start" onClick={() => goDetail(notify['SO_APPROVE'].status, notify['SO_APPROVE'].type_maintenance,'Approve')}>
                <Media left>
                  <Icon.PlusSquare
                    className="font-medium-5 info"
                    size={21}
                  />
                </Media>
                <Media body>
                  <Media heading className="media-heading" tag="h6">
                    SO to be Approve
                  </Media>
                  <p className="notification-text">
                    Approve the task
                  </p>
                </Media>
                <div className="media-meta">
                  <Badge pill color={notify['SO_APPROVE'].jumlah > 0 ? "primary" : "light" }>{notify["SO_APPROVE"].jumlah || 0}</Badge>
                </div>
              </Media>
            </div>

            <div className="d-flex justify-content-between">
              <Media className="d-flex align-items-start" onClick={() => goDetail(notify['SO_COMPLETE'].status, notify['SO_COMPLETE'].type_maintenance,'Complete')}>
                <Media left>
                  <Icon.CheckCircle
                    className="font-medium-5 success"
                    size={21}
                  />
                </Media>
                <Media body>
                  <Media heading className="media-heading" tag="h6">
                    SO to be Complete
                  </Media>
                  <p className="notification-text">
                    Complete the task
                  </p>
                </Media>
                <div className="media-meta">
                  <Badge pill color={notify['SO_COMPLETE'].jumlah > 0 ? "primary" : "light" }>{notify["SO_COMPLETE"].jumlah || 0}</Badge>
                </div>
              </Media>
            </div>

            <div className="d-flex justify-content-between">
              <Media className="d-flex align-items-start" onClick={() => goDetail(notify['SO_CLOSE'].status, notify['SO_CLOSE'].type_maintenance,'Close')}>
                <Media left>
                  <Icon.XSquare
                    className="font-medium-5 dark"
                    size={21}
                  />
                </Media>
                <Media body>
                  <Media heading className="media-heading" tag="h6">
                    SO to be Close
                  </Media>
                  <p className="notification-text">
                    Close the task
                  </p>
                </Media>
                <div className="media-meta">
                  <Badge pill color={notify['SO_CLOSE'].jumlah > 0 ? "primary" : "light" }>{notify["SO_CLOSE"].jumlah || 0}</Badge>
                </div>
              </Media>
            </div>


          </div>

        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600">
              {userdata.username}
            </span>
            <span className="user-status">online</span>
          </div>
          <Avatar status="online" icon={<Icon.User size={25} />} />
        </DropdownToggle>
        <UserDropdown userdata={userdata} />
      </UncontrolledDropdown>
      </ul>
  )
}

export default NavbarUser
