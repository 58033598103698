import AuthSession,{Config} from './global';
const axios = require('axios').default;

class Api{

  get(url,{body,params,jwt=true,headers,responseType,callback}={}){

    if(typeof callback === 'function'){
      return this.request('GET',url,{body,params,jwt,headers,responseType,callback})
    }

    return new Promise(resolve => {
      this.request('GET',url,{body,params,jwt,headers,callback:(cb)=>{
        resolve(cb)
      }})
    });

  }

  post(url,{body,params,jwt=true,headers,callback}={}){
    this.request('POST',url,{body,params,jwt,headers,callback})
  }

  patch(url,{body,params,jwt=true,headers,callback}={}){
    this.request('PATCH',url,{body,params,jwt,headers,callback})
  }

  put(url,{body,params,jwt=true,headers,callback}={}){
    this.request('PUT',url,{body,params,jwt,headers,callback})
  }

  delete(url,{body,params,jwt=true,headers,callback}={}){
    this.request('DELETE',url,{body,params,jwt,headers,callback})
  }

  download(url,{body,params,jwt=true,headers,callback}={}){
    return this.request('GET',url,{body,params,jwt,headers,responseType:"blob",callback})
  }

  endpoint(){
    return Config.API_ENDPOINT
  }

  request(method='GET',url,{body,params,jwt=true,headers,responseType,callback}={}){
    if(typeof callback === 'undefined') callback = ()=>{};

    let token;

    url = Config.API_ENDPOINT + url;


    if(jwt){
      token = AuthSession.getToken();
    }

    var response;

    if(!headers){
      headers = {
        "content-type": "application/json"
      }
    }

    if(jwt){
      headers.Authorization = "Bearer " + token;
      /*
      headers = {
        "content-type": "application/json",
        "Authorization": "Bearer " + token
      };
      */
    }

    let timeout = 45 * 1000

    switch (method) {
      case 'POST':
        response = axios.post(url,body,{
          params,
          timeout: timeout,
          headers,
        });
        break;
      case 'PATCH':
        response = axios.patch(url,body,{
          params,
          timeout: timeout,
          headers: headers
        });
        break;
      case 'PUT':
        response = axios.put(url,body,{
          params,
          timeout: timeout,
          headers: headers
        });
        break;
      case 'DELETE':
        response = axios.delete(url,{
          params,
          timeout: timeout,
          headers: headers
        });
        break;

      case 'GET':
        response = axios.get(url,{
          params: params,
          timeout: timeout,
          headers: headers,
          responseType
        });
        break;

      default:
        return callback({
          success:false,
          data:{
            general: 'Request method required'
          }
        })

    }

    response.then(function (response) {
      /*
      let {success,data} = response.data

      if(success){
        callback(response.data)
      }else{

        if(typeof data.general === 'object'){
          data.general = JSON.stringify(data.general,null,2)
        }
        callback({success,data})
      }
      */
      callback(response.data)
    })
    .catch(function (err,res) {

      if(err.code === 'ECONNABORTED'){
        return callback({success:false,data:{general: err.message}});
        //return callback({error: error.message},error.code);
      }
      if(err.message === 'Network Error'){
        return callback({success:false,data: {general: err.message}});
      }

      let resp = err.response

      if(!resp){
        return callback({success:false,data: {general: err.message}});
      }

      if(resp.status === 404){
        return callback({success:false,data: {general: resp.statusText + ' | ' + url}});
      }

      if(resp.status === 401){
        //setTimeout(() => {
          window.location.replace('/login?ref=' + window.location.pathname)
          return
        //}, 1000);
      }


      if(resp.data){
        let {success,data} = resp.data
        if(!success && !data){
          return callback({success:false,data: {general: 'error null'}});
        }
      }
      callback(resp.data);
      //callback({error:error},response.status);

    });

  }


}

export default new Api();
